import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer, Slide } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import useNotificationPermission from 'hooks/useNotificationPermission';
import { ReactComponent as ErrorIcon } from '../../../images/error-icon.svg';
import { ReactComponent as SuccessIcon } from '../../../images/success-icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Notifications.module.scss';

function getBackgroundColor(type) {
  return (
    {
      success: '#EDF7ED',
      error: '#FEECEB',
    }[type] || ''
  );
}

function Notifications({ notifications = [], removeByUid, navigate }) {
  useNotificationPermission();

  const displayNotificationText = (type, text, data) => (
    <div className={styles.notification} data-cy="notification-alert">
      {data ? (
        <div className={styles.wrapper}>
          <span className={styles.text}>{text}</span>
          <span
            className="spiro-link bold-500"
            onClick={() => navigate(`/contacts/${data.id}`)}
            role="button"
            tabIndex="0"
          >
            {data.first_name} {data.last_name}
          </span>
        </div>
      ) : (
        <span className={styles.span}>{text}</span>
      )}
    </div>
  );

  const removeNotification = (uid) => {
    removeByUid(uid);
  };

  const drawNotifications = (data) => {
    data.forEach((n) => {
      toast[n.type](displayNotificationText(n.type, n.text, n.data), {
        toastId: n.uid,
        style: {
          width: '400px',
          backgroundColor: getBackgroundColor(n.type),
        },
        onClose: () => {
          removeNotification(n.uid);
        },
        closeButton: (
          <CloseIcon
            fontSize="small"
            className={styles.icon}
            onClick={(e) => {
              toast.dismiss(n.uid);
              removeNotification(n.uid);
              e.stopPropagation();
            }}
          />
        ),
        autoClose: 5000,
        position: 'top-center',
      });
    });
  };

  useEffect(() => {
    if (notifications.length) {
      drawNotifications(notifications);
    }
  }, [notifications]);

  return (
    <ToastContainer
      data-cy="notification-message"
      transition={Slide}
      newestOnTop
      style={{ top: '75px' }}
      icon={({ type }) => {
        if (type === 'success') return <SuccessIcon />;
        if (type === 'error') return <ErrorIcon />;
        return null;
      }}
    />
  );
}

Notifications.propTypes = {
  removeByUid: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Notifications;
