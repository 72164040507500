import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Autocomplete.module.scss';

const ListBox = forwardRef(({ checkboxOption, children, ...rest }, ref) => (
  <div>
    {checkboxOption && <div className={styles['checkbox-container']}>{checkboxOption}</div>}
    <ul {...rest} ref={ref} role="listbox" style={{ paddingTop: 0 }}>
      {children}
    </ul>
  </div>
));

ListBox.defaultProps = {
  checkboxOption: null,
};

ListBox.propTypes = {
  checkboxOption: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default ListBox;
