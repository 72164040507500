import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setSelectedRule,
  updateStateForAssistantRules,
} from 'app/assistant/Assistant/state/reducer';
import {
  createRuleCondition,
  getConditionDefinitions,
  getConditionsForRule,
  getEngineRule,
  updateEngineRule,
  updateRuleCondition,
  getCalculationFields,
  getUserPickerOptionForAssistantRule,
  getRuleActionFields,
  createEngieRulesActions,
  updateEngieRulesAction,
  getEmailTemplateFields,
} from 'services/engine-rules';
import { getGroups } from 'services/groups';
import { error, success as successAlert } from 'state/notifications/actions';
import { forEachError } from '../../../../helpers/errorHelper';
import { setEngineRules } from '../../EngineRules/state/data/reducer';

export const fetchEngineRule = createAsyncThunk('engine-rule-get', async (ruleId, { dispatch }) => {
  try {
    const res = await getEngineRule(ruleId);
    return res;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const handleUpdateEngineRule = createAsyncThunk(
  'engine-rule-update',
  async (data, { dispatch, getState }) => {
    const { payload, ruleId } = data;

    try {
      const res = await updateEngineRule(ruleId, payload);
      const rules = getState().engineRules.data.map((rule) => {
        if (rule.id === ruleId) {
          return res.assistant_rule;
        }
        return rule;
      });
      dispatch(setEngineRules(rules));
      if (
        Object.prototype.hasOwnProperty.call(payload, 'description') ||
        Object.prototype.hasOwnProperty.call(payload, 'name') ||
        Object.prototype.hasOwnProperty.call(payload, 'enabled_on_dashboard') ||
        Object.prototype.hasOwnProperty.call(payload, 'status') ||
        Object.prototype.hasOwnProperty.call(payload, 'enabled_on_assistant_tab')
      ) {
        const assistantRules = getState().assistant.assistantRules;
        const newAssistantRules = assistantRules.map((rule) => {
          if (rule.id !== ruleId) return rule;
          return { ...rule, ...payload };
        });
        dispatch(updateStateForAssistantRules({ assistantRules: newAssistantRules }));
      }

      if (Object.prototype.hasOwnProperty.call(payload, 'calculations')) {
        const assistantRules = getState().assistant.assistantRules;
        const selectedRule = getState().assistant.selectedRule;
        const newAssistantRules = assistantRules.map((rule) => {
          if (rule.id !== ruleId) return rule;
          return res.assistant_rule;
        });
        if (selectedRule.value === ruleId) {
          dispatch(
            setSelectedRule({
              ...selectedRule,
              isCurrency: res.assistant_rule.calculation_is_currency,
            })
          );
        }
        dispatch(updateStateForAssistantRules({ assistantRules: newAssistantRules }));
        dispatch(successAlert('Value updated.'));
      }
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchRuleConditions = createAsyncThunk(
  'engine-rule-conditions-get',
  async (payload, { dispatch, getState }) => {
    try {
      const reqParams = { ...getState().engineRule.conditionsParams, ...payload.params };
      const recordsPerPage = getState().spiroViews.recordsPerPage;
      const defaultParams = { ...reqParams, per_page: recordsPerPage };
      const res = await getConditionsForRule(payload.ruleId, defaultParams);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchGroups = createAsyncThunk('engine-rule-groups-get', async (_, { dispatch }) => {
  try {
    const res = await getGroups();
    return res.map((group) => ({ label: group.name, value: group.id }));
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const fetchConditionDefinitions = createAsyncThunk(
  'engine-rule-conditions-definition-get',
  async (params = {}, { dispatch }) => {
    try {
      const res = await getConditionDefinitions(params);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleCreateRuleCondition = createAsyncThunk(
  'create-rule-condition',
  async (data, { dispatch }) => {
    try {
      const res = await createRuleCondition(data.ruleId, data.payload);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleUpdateRuleCondition = createAsyncThunk(
  'update-rule-condition',
  async (data, { dispatch }) => {
    try {
      const res = await updateRuleCondition(data.ruleId, data.criteriaId, data.payload);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchCalculationFields = createAsyncThunk(
  'assistant-rules-calculation-fields',
  async (resource, { dispatch }) => {
    try {
      const res = await getCalculationFields(resource);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchUserFieldOptionsForAssistantRule = createAsyncThunk(
  'assistant-rules-user-options',
  async (resource, { dispatch }) => {
    try {
      const res = await getUserPickerOptionForAssistantRule(resource);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchEngineRuleActionFields = createAsyncThunk(
  'assistant-rules-action-fields',
  async (resource, { dispatch }) => {
    try {
      const res = await getRuleActionFields(resource);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const createRuleActionFields = createAsyncThunk(
  'create-rule-action-fields',
  async (data, { dispatch }) => {
    try {
      const res = await createEngieRulesActions(data.ruleId, data.payload);
      dispatch(successAlert('Assistant rule action successfully created!'));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleUpdateEngieRulesAction = createAsyncThunk(
  'update-rule-action-fields',
  async (data, { dispatch }) => {
    try {
      const res = await updateEngieRulesAction(data.ruleId, data.actionId, data.payload);
      dispatch(successAlert('Assistant rule action successfully updated!'));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchEmailTemplateFields = createAsyncThunk(
  'assistant-rules-email-templates-fields',
  async (ruleId, { dispatch }) => {
    try {
      const res = await getEmailTemplateFields(ruleId);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
