import { useState } from 'react';
import PropTypes from 'prop-types';
import Day from 'dayjs';
import { ClickAwayListener } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D43CE',
    },
  },
});

export default function DatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(props.value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onChange(date);
  };

  const handleOnSelect = (val) => {
    props.onSelect(new Day(val).format('YYYY-MM-DD'));
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.onClose();
    }
  };

  const handleClickAway = () => {
    props.onClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ display: 'inline' }}>
            <MuiDatePicker
              {...props}
              disabled={props.disabled}
              onChange={handleDateChange}
              label={props.label}
              value={selectedDate}
              onAccept={handleOnSelect}
              slotProps={{
                textField: {
                  onKeyPress,
                  variant: 'filled',
                  inputProps: { 'data-cy': 'date-picker-input' },
                },
                openPickerButton: {
                  'data-cy': `${props.dataCyIcon || 'calendar-icon'}`,
                },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: 'transparent',
                  width: '100%',
                },
                '& .MuiFilledInput-root:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </div>
        </ClickAwayListener>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

DatePicker.defaultProps = {
  variant: 'filled',
  autoFocus: false,
  keyboard: false,
  value: null,
  onClose: () => {},
  onSelect: () => {},
  className: 'full-width',
};

DatePicker.propTypes = {
  variant: PropTypes.string,
  autoFocus: PropTypes.bool,
  keyboard: PropTypes.bool,
  value: PropTypes.object,
  onClose: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};
