import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from 'lib/Button';
import { ReactComponent as NewVersionImage } from '../../../images/new-version.svg';
import styles from './NewVersionPage.module.scss';

function NewVersionPage() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <NewVersionImage />
          <Typography variant="h6" className={styles.heading}>
            New version of Spiro available!
          </Typography>
          <Typography variant="body1">Refresh the page to access Spiro.</Typography>
          <Button
            onClick={refreshPage}
            variant="contained"
            color="primary"
            className={styles.button}
          >
            Refresh
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default NewVersionPage;
