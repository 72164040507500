import {
  STORE_CUSTOM_FIELDS,
  STORE_CUSTOM_FIELD,
  FETCHING_CUSTOM_FIELDS,
  FETCHING_CUSTOM_FIELDS_FAILED,
  SELECT_CUSTOM_FIELDS_RESOURCE,
  REPLACE_CUSTOM_ENTITY,
  REMOVE_CUSTOM_FIELD,
  REMOVE_CUSTOM_ENTITY,
  STORE_NEW_CUSTOM_FIELDS_RESOURCE,
} from './contants';
import {
  get as getCustomFieldsRemote,
  create as createCustomFieldRemote,
  update as updateCustomFieldRemote,
  del as deleteCustomFieldRemote,
} from '../../services/custom-fields';
import { getDisplayFields } from '../display-fields/actions';
import { fetchSpiroViews } from '../views/actions';
import {
  serializeCustomField,
  parseFieldsFromCustomField,
} from '../../components/CustomFields/helper';
import { resolveCustomObjectType } from '../../helpers/custom-fields';
import { success as showSuccessAlert, error as showErrorAlert } from '../notifications/actions';
import { forEachError } from '../../../helpers/errorHelper';

export function fetching() {
  return {
    type: FETCHING_CUSTOM_FIELDS,
  };
}

export function storeCustomFields(data) {
  return {
    type: STORE_CUSTOM_FIELDS,
    data,
  };
}

export function storeCustomField(resource) {
  return {
    type: STORE_CUSTOM_FIELD,
    resource: { ...resource, fields: parseFieldsFromCustomField(resource) },
  };
}

export function storeNewCustomFieldsResource(resource) {
  return {
    type: STORE_NEW_CUSTOM_FIELDS_RESOURCE,
    resource,
  };
}

export function fetchFailed(data) {
  return {
    type: FETCHING_CUSTOM_FIELDS_FAILED,
    data,
  };
}

export function selectCustomFieldsResource(resource) {
  return {
    type: SELECT_CUSTOM_FIELDS_RESOURCE,
    resource,
  };
}

export function replaceCustomEntity(resource, resourceId) {
  return {
    type: REPLACE_CUSTOM_ENTITY,
    resource,
    resourceId,
  };
}

export function removeCustomField(name) {
  return {
    type: REMOVE_CUSTOM_FIELD,
    name,
  };
}

export function removeCustomEntity(resource) {
  return {
    type: REMOVE_CUSTOM_ENTITY,
    resource,
  };
}

export function getCustomFields() {
  return (dispatch) => {
    dispatch(fetching());
    return getCustomFieldsRemote()
      .then((r) => dispatch(storeCustomFields(r)))
      .catch((e) => dispatch(fetchFailed(e)));
  };
}

export function createCustomField(data) {
  const payload = serializeCustomField(data);

  return (dispatch) =>
    createCustomFieldRemote(payload).then((r) => {
      dispatch(
        storeCustomField({
          ...r,
          schema_type: resolveCustomObjectType(r.schema_type),
        })
      );
      dispatch(getCustomFields());
      dispatch(getDisplayFields());
      dispatch(fetchSpiroViews());

      return r;
    });
}

export function updateCustomField(id, data) {
  const payload = serializeCustomField(data);

  return (dispatch) =>
    updateCustomFieldRemote(id, payload)
      .then((response) => {
        dispatch(getCustomFields());
        dispatch(getDisplayFields());
        dispatch(fetchSpiroViews());
        return response.custom_field;
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(showErrorAlert(e)));
        return Promise.reject(err);
      });
}

export function deleteCustomField(id, name) {
  return (dispatch) =>
    deleteCustomFieldRemote(id, { name }).then(() => {
      dispatch(getDisplayFields());
      dispatch(removeCustomField(name));
    });
}

export function deleteCustomEntity(id, resource) {
  return (dispatch) =>
    // if (!id) {
    //   dispatch(removeCustomEntity(resource));
    //   onSuccess();
    //   return false;
    // }
    deleteCustomFieldRemote(id, { resource })
      .then(() => {
        // dispatch(removeCustomEntity(resource));
        dispatch(getCustomFields());
        dispatch(showSuccessAlert('Entity successfully deleted.'));
        return Promise.resolve();
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(showErrorAlert(e)));
        return Promise.reject(err);
      });
}

export default {
  fetching,
  storeCustomFields,
  storeCustomField,
  createCustomField,
  deleteCustomField,
  fetchFailed,
  getCustomFields,
  selectCustomFieldsResource,
};
