import numeral from 'numeral';
import 'numeral/locales';
import { showModal } from 'modals/state/actions';
import { fetchSupportDesks } from 'routes/Settings/SupportDesks/state/actions';
import { fetchTimezones } from 'app/companies/Account/state/actions';
import { getUser as getUserRemote, updateUser as updateUserRemote } from '../../services/users';
import {
  FETCHING_USER,
  STORE_USER,
  SET_NOTIFICATIONS_STATUS,
  UPDATE_IN_PROGRESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  UPDATE_ENABLE_PARENT_ACCOUNT,
  TOGGLE_MANAGER_SUMMARY_MODAL,
  STORE_MENTIONABLE_USERS,
} from './constants';
import { parseUserFromEngine } from '../../helpers/user';
import { storeTokens } from '../tokens/actions';
import { storeCredentials } from '../credentials/actions';
import { storeCalendar } from '../calendar/actions';
import { storeTwilioProfile } from '../twilio-profile/actions';
import { storeOrganization } from '../organization/actions';
import { setSubscription } from '../subscription/actions';
import { getUsers, getAllUsers } from '../users/actions';
import { getSubUsers } from '../sub-users/actions';
import { getCurrencies } from '../currencies/actions';
import { getCustomFields } from '../custom-fields/actions';
import { getDisplayFields } from '../display-fields/actions';
import { getCounters } from '../counters/actions';
import { getAssistantRules } from '../../assistant/Assistant/state/actions';
import i18n from '../../../i18n';
import { fetchSpiroViews } from '../views/actions';
import { getMentionableUsers } from '../../services/collaboration-notes';
import { fetchRingCentralConfig } from '../ring-central/actions';

export function fetchingUserData() {
  return {
    type: FETCHING_USER,
  };
}

export function storeUser(data) {
  return {
    type: STORE_USER,
    data: parseUserFromEngine(data),
  };
}

export function storeUserLocally(data) {
  return {
    type: STORE_USER,
    data,
  };
}

export function setNotificationsStatus(status) {
  return {
    type: SET_NOTIFICATIONS_STATUS,
    status,
  };
}

export function editEnableParentAccount(data) {
  return {
    type: UPDATE_ENABLE_PARENT_ACCOUNT,
    data,
  };
}

export function updateCurrentUser(data, params) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_IN_PROGRESS });

    return updateUserRemote(getState().user.id, data, params)
      .then((res) => {
        dispatch(storeUser(res));
        dispatch(storeTwilioProfile(res.twilio_profile));
        dispatch({ type: UPDATE_SUCCESS });
      })
      .catch((e) => {
        dispatch({ type: UPDATE_FAIL });
        throw e;
      });
  };
}

export function storeMentionableUsers(data) {
  return {
    type: STORE_MENTIONABLE_USERS,
    data,
  };
}

export function fetchMentionableUsers(params = {}) {
  return (dispatch) =>
    getMentionableUsers(params)
      .then((json) => {
        dispatch(storeMentionableUsers(json));
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
}

export function getUser(id, navigate) {
  return (dispatch) =>
    // dispatch({ type: UPDATE_IN_PROGRESS });
    getUserRemote(id)
      .then((json) => {
        const { user } = json;
        const locale = user.organization.locale || 'en';
        i18n.changeLanguage(locale);
        // numeral.locale(locale === 'en-CA' ? 'en' : locale);
        numeral('en');
        dispatch(storeUser(user));

        if (user.calendar) {
          dispatch(
            storeCalendar({
              uid: user.calendar.uid,
              name: user.calendar.title,
            })
          );
        }
        dispatch(storeTokens(user.tokens));
        dispatch(storeCredentials(user.credentials));
        dispatch(storeOrganization(user.organization));
        dispatch(
          setSubscription({
            customerStatus: user.customer_status,
            customerDaysLeft: user.customer_days_left,
            customerExpiresAt: user.customer_expires_at,
            customerSince: user.customer_since,
            trialDaysLeft: user.trial_days_left,
            trialExpiresAt: user.trial_expires_at,
          })
        );
        dispatch(storeTwilioProfile(user.twilio_profile));

        dispatch(
          updateCurrentUser({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        ).catch((e) => {
          console.error(e);
        });
        dispatch(fetchRingCentralConfig());
        dispatch(getUsers());
        dispatch(getSubUsers());
        dispatch(getCustomFields());
        // fetch views on refresh
        dispatch(fetchSpiroViews());
        dispatch(getDisplayFields());
        dispatch(getCurrencies());
        dispatch(getAllUsers({ include_deactivated: true }));
        dispatch(getAssistantRules({ user_group_only: true }));
        dispatch(fetchMentionableUsers());
        dispatch(fetchTimezones());
        if (user.addons.find((product) => product.name === 'Support Desk')) {
          dispatch(fetchSupportDesks());
        }

        if (user.twilio_profile) {
          dispatch(getCounters());
        }

        if (!user.voip_popup_disabled && user.twilio_profile && user.status === 'active') {
          dispatch(showModal('VOIP_ENABLEMENT_MODAL', {}));
        }

        return Promise.resolve(user);
      })
      .catch((e) => {
        if (e.status === 403 && e.data.errorCode === 'EMAIL_ONLY_LICENCE') {
          navigate('/request-full-licence');
        }
        Promise.reject(e);
      });
}

export function toggleManagerSummaryModal(data) {
  return {
    type: TOGGLE_MANAGER_SUMMARY_MODAL,
    data,
  };
}

export default {
  fetchingUserData,
  storeUser,
  updateCurrentUser,
  setNotificationsStatus,
  toggleManagerSummaryModal,
  fetchMentionableUsers,
};
